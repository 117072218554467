<template>
  <div id="guideSuccess">
    <div class="header-nav flex-align">
      <img :src="themeData.darkLogoList">
      <div class="nav-right flex-align" @click="languageChange">
        <global-outlined class="ic-lanage" />
        <span class="text">{{ language === 'zh' ? 'English' : '中文' }}</span>
      </div>
    </div>
    <div class="common-card-body">
      <div class="apply-result">
        <div class="title flex-align">
          <check-circle-outlined class="ic-success" />
          <span>{{ $t('pages_merchant_105') }}</span>
        </div>
        <div class="desc">
          <span>{{ $t('pages_merchant_127') }}</span>
        </div>
        <div v-if="isBack === 'true'" class="edit">
          <a-button
            shape="round"
            style="margin-left: 18px"
            class="success-btn"
            type="primary"
            @click="$router.replace('/home/index')"
          >{{ $t('pages_router_012') }}</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, inject } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
export default {
  name: 'GuideSuccess',
  setup () {
    const store = useStore()
    const route = useRoute()
    const state = reactive({
      themeData: inject('$themeData'),
      isBack: route.query.isBack,
      language: localStorage.getItem('locale-language') || 'zh'
    })

    const languageChange = async (e) => {
      localStorage.setItem('locale-language', state.language === 'zh' ? 'en' : 'zh')
      await store.dispatch('getLanguage')
      location.reload()
    }

    return {
      languageChange,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less">
#guideSuccess {
  .header-nav {
    height: 60px;
    padding: 0 24px;
    margin-bottom: 4px;
    box-shadow: 0 0 6px 0 #d9d9d9;
    img {
      width: 150px;
    }
    .nav-right {
      margin-left: auto;
      cursor: pointer;
      .ic-lanage {
        color: @main-color;
        font-size: 24px;
      }
      .text {
        margin-left: 8px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .common-card-body {
    padding: 18px 36px 64px 36px;
    .apply-result {
      margin-top: 60px;
      .title {
        justify-content: center;
        font-size: 24px;
        font-weight: 500;
        .ic-success {
          color: #02A900;
          margin-right: 8px;
          font-size: 28px;
        }
      }
      .desc {
        margin-top: 32px;
        .link-line {
          color: @main-color;
          padding: 0 2px;
          cursor: pointer;
        }
      }
      .edit {
        margin-top: 36px;
        .success-btn {
          min-width: 138px;
        }
      }
    }
  }
}
</style>
